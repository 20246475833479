.card {

  border: none;
  background: $lbrown;
  padding: 20px;
  color: $gray;

  h4 {

    color: $lblue;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $lblue
  }

  p {

    text-align: left;
  }

  .mb-negative {

    margin-bottom: -60px;
  }

  &.card-sm {

    background: $coWhite;
    align-items: center;
    border: 1px solid $coWhite;
    opacity: 0.4;
    border-radius: 7px;

    h4 {

      color: $orange;
      margin-bottom: 0;
    }

    &.active {

      opacity: 1;
      border-color: $orange;
    }
  }
  
  &.card-notice {

    h3 {

      color: $gray;
    }
  }
}

.card-b {

  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background: $dyellow;
  color: $coWhite;

  .card-image {

    border-radius: inherit;

    img {

      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }

  .card-body {

    padding: 20px;

    h3 {

      margin-bottom: 20px;
    }
  }
}

.card-plain {

  position: relative;
  max-width: 768px;

  .card-p-text {

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 140px;
    padding: 15px 20px;
    background: rgba($coBlue, 0.8);
    color: $white;
  }

}

.card-white {

  background: none;

  p {

    color: $gray;
  }
}