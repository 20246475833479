// this is area is for reserved class
#main-container { 
  width: 100%;
  min-height: 100%;
	position: relative;
	flex-direction: column;
  display: flex;
  z-index: 1;
  font-size: rem(16px);

}

main {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  z-index: 1;
  overflow: hidden;
}

.hide { position:relative; }
.ease,a,input[type="submit"],button { @include transition(all 0.5s ease); }
.ease2 { @include transition(all 0.3s ease); }
.loader { position:relative; overflow:hidden; background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.auto { max-width:$maxWidth; margin-left: auto; margin-right: auto; }
.img-auto { width: 100%; height: auto; }

.img-objectfit {

  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.container {

  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;

  .container-right-full {

    width: calc(100% + 30vw);

    @include media-breakpoint-down(xxl) {

      width: 100%;
    }
  }
}

.container-sm {

  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {

  padding-left: 20px;
  padding-right: 20px;
}

.zx1 { z-index: 1; }
.zx2 { z-index: 2; }
.zx3 { z-index: 3; }
.zx4 { z-index: 4; }
.zx5 { z-index: 5; }
.zx6 { z-index: 6; }
.overflow { overflow: hidden; }
.pl-0 { padding-left: 0; }
.pr-0 { padding-right: 0; }

.preloader { position:fixed; width:100%; height:100%; overflow:hidden; }
.explorer .preloader { background:#fff url('../images/page_template/loading.gif') no-repeat center; }
.explorer .preloader .spinner { display:none; }
// this is area is for reserved class

// you can include here the rest of your class name eg. home page

.form-amend {

  &[aria-hidden="true"] {
    display: none;
  }
}

a {

  &:hover {

    color: $orange;
  }
}


.btn {

  padding: 10px 20px;
  background: $orange;
  color: $white;
  border-radius: 30px;

  i {

    margin-left: 10px;
  }

  small {

    display: block;
    font-size: rem(12px);
    margin: -6px 0;

    u {

      display: block;
    }
  }

  &:hover {

    background: darken($orange, 10%);
  }

  &.btn-outline {

    background: none;
    border: 1px solid $white;
    min-width: 130px;

    &.btn-orange {

      border: 1px solid $orange;
      color: $orange;
    }

    &.btn-gray {

      border: 1px solid $gray;
      color: $gray;

      &:hover {

        color: $coWhite;
      }
    }

    &:hover {

      border-color: $orange;
      background: $orange;
    }
  }  

  &.btn-ghost {

    background: none;
    border: 1px solid transparent;
    color: $black;

    img, i {

      margin-left: 10px;
      margin-top: -5px;
    }

    &:hover,
    &.active {

      border: 1px solid $orange;
      color: $orange;
    }
  }

  &:hover {

    color: $coWhite;
  }

  &.btn-dark {

    border-color: $gray;
    color: $gray;

    &:hover,
    &.active {

      border-color: $orange;
      background: $orange;
      color: $white;
    }
  }
}

.btn-link {

  text-decoration: none;
  color: $black;

  i {

    margin-left: 10px;
  }

  &.btn-underline {

    position: relative;

    &:after {

      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      content: '';
      background: $defaultColor;
    }
  }
}

.sns {

  display: flex;

  a {

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(20px);
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $white;

    &:hover  {

      opacity: 1 !important;
      background: $white;
      color: $coBlue;
    }
  }
}

.w-text {

  color: $white;
}

.d-text {

  color: $defaultColor;
}

.b-text {

  color: $blue;
}

.y-text {

  color: $yellow;
}

.o-text {

  color: $orange;
}

.col-blue {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $coBlue;
  color: $coWhite;

  &.col-lblue {

    background: $lblue;
  }

  h3 {

    text-align: center;
  }
}

.col-light {

  background: $gray-10;
}

.grid-text {

  max-width: 570px;
  margin-left: auto;
}

.count {

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  .counters {

    width: 50%;
    flex-grow: 1;

    strong {

      display: block;
      font-size: rem(100px);
    }
  }
}

.text-flex-list {

  border-top: 1px solid rgba($coWhite, 0.3);
  padding: 15px 0;
}

.video-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $coWhite;
}

.video-thumb {

  position: relative;
  cursor: pointer;
  // padding-bottom: 55%;

  video, 
  iframe {
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  &.play {

    video, 
    iframe {
      
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 2;
    }
  }

}

.videos {

  position: relative;
  width: 100%;
  height: 100%;

  video, iframe {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.col-yellow {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background: $yellow;

  .col-yellow-inner {

    width: 100%;
    max-width: 650px;
  }
  
}

.col-graphics {

  position: relative;

  &.overlay {

    &:after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      @include linear-gradient(to top, $black, transparent);
    }
  }

  .col-caption {

    position: absolute;
    width: 100%;
    bottom: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    z-index: 2;
  }
}

.col-content {

  position: relative;

  h2 {

    color: $black !important;
  }

  h3 {

    margin-bottom: 20px;
  }

  .table-container {

    + h4 {

      margin-top: 40px;
    }
  }

  .col-content-auto {

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: auto;
    width: calc(100%);
    min-height: 400px;

    &::-webkit-scrollbar {
      width: 5px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    @include media-breakpoint-down(lg) {

      position: relative;

    }

  }
}

.col-content-right {

  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-left: auto;
  max-width: 600px;

  @include media-breakpoint-down(lg) {

    max-width: 100%;
  }
}

.col-center {

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.col-content-flex {

  display: flex;
}

.col-center-justify {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content-list {

    h3 {

      margin-top: 30px;
      margin-bottom: 0;
    }

  }

  @include media-breakpoint-down(lg) {

    align-items: flex-start;
  }
}

.gray-text {

  p {

    color: $gray;
  }
}

.circle-content {

  border-radius: 50%;
}

.slide-content {

  position: relative;
  min-height: 400px;

  .img-objectfit {

    min-height: 400px;
  }

  h3 {

    margin-bottom: 20px;
  }

  &.slide-overlay {

    &:after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      @include linear-gradient(to top, rgba($black, 0.8) 0, transparent 100%);
    }
  }

  .slide-text {

    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;
    z-index: 2;
    color: $white;

    .slide-text-inner {

      max-width: 800px;
      padding-left: 30px;
      padding-right: 30px;
      margin: 0 auto;
    }
  }
}

.swiper-right-bottom {

  position: absolute;
  bottom: -40px;
  right: 12vw;

  .swiper-right-bottom-inner {

    position: relative;
    width: 200px;
  }

  @include media-breakpoint-down(xxl) {

    right: 0;
  }
}

ol {
  
  padding-left: 20px;
  margin-bottom: 30px;

  li {

    list-style: decimal;
    padding-left: 5px;
  }
}

ul li {

  list-style: none;
}

.label-wide {

  min-width: 110px;
}

.bg-text {

  padding: 20px 20px;
  border-radius: 8px;
  background: $ddblue;
  color: $coWhite;
  text-align: center;
  min-height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.yellow {

    background: $yellow;
  }

  &.orange {

    background: $orange;
  }

  h3 {

    font-size: rem(22px);
    margin-bottom: 0;
  }
}

.list {

  margin-top: 30px;

  h3 {

    margin-bottom: 0;
  }

  p {
    
    margin-bottom: 0;
  }
}

.map {

  width: 100%;
  height: 500px;

  iframe {

    width: 100%;
    height: 100%;
  }
}

.circle-img {

  position: relative;
  border-radius: 50%;
  overflow: hidden;
  color: $coWhite;

  > img {

    border-radius: inherit;
  }

  .circle-inner {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    span {

      display: block;
      font-weight: bold;
    }

    strong {

      font-size: 8.5vw;
      font-weight: normal;
    }

    @include media-breakpoint-down(md) {

      strong {

        font-size: 20vw;
      }
    }

    @include media-breakpoint-down(sm) {

      padding: 20px;
    }

  }
}