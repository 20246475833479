.hero-banner {

  position: relative;
  min-height: 500px;

  .img-objectfit {

    min-height: 730px;
  }

  .hero-content {

    position: absolute;
    top: 50px;
    left: 0;
    padding: 50px;
    width: 100%;
    height: 100%;
    color: $coWhite;
  }

  @include media-breakpoint-down(lg) {

    .hero-content {

      top: 65px;
      padding: 20px;
    }

    .hero-inner {

      width: calc(100% - 110px);
    }

  }

}

.hero-nav {

  position: absolute;
  top: 120px;
  right: 50px;
  z-index: 2;

  ul {

    padding-left: 0;

    li {

      margin-bottom: 15px;

      a {
        
        border-bottom: 1px solid $coWhite;
        color: $coWhite;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    top: 90px;
    right: 30px;
  }
}

.inner-banner {

  h1 {

    color: $yellow;
    margin-bottom: 30px;
    // text-shadow: 1px 1px 1px rgba($black, 0.4);
  }

  .container-sm & {
    
    padding-top: 80px;
  }
}