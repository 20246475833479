.section {

  position: relative;
  padding: 60px 0;

  &:first-child {

    padding-top: 80px !important;

    @include media-breakpoint-down(xxl) {

      padding-top: 70px !important;
    }

    @include media-breakpoint-down(xl) {

      padding-top: 65px !important;
    }
  }

  &.section-center {

    .container,
    .container-sm {

      > h2 {

        text-align: center;

        + p {

          text-align: center;
        }
      }
    }
  }

  &.section-hero {
    
    padding-bottom: 47% !important;
    min-height: 500px;
    padding: 0;

    &:after {

      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      content: '';
      background: url('../images/home/Path-98_01.png') no-repeat bottom center;
      background-size: 100%;
      z-index: 2;
    }

    &.section-hero-yellow {

      &:after {

        background: url('../images/home/Path-98_02.png') no-repeat bottom center;
        background-size: 100%;
      }

      .hero-banner {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.section-hero-dblue {

      &:after {

        background: url('../images/home/Path-98_03.png') no-repeat bottom center;
        background-size: 100%;
      }
    }

    &.fixed-banner {

      .hero-banner {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .swiper-holder {

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.section-hero-sm {

    padding-bottom: 0;
    min-height: 550px;

    .img-objectfit {

      min-height: 550px;
    }

    .inner-banner {

      position: absolute;
      top: 50px;
      left: 0;
      padding: 50px;
      width: 100%;
      height: 100%;
      z-index: 5;

      h1 {

        margin-top: 80px;
      }
    }

    @include media-breakpoint-down(lg) {

      min-height: 450px;

      .img-objectfit {

        min-height: 450px;
      }

      .inner-banner {
  
        h1 {
  
          margin-top: 60px;
        }
      }
    }
  }

  &.section-bg {

    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }

  &.section-highlight {

    background: $gray-50;
  }

  &.section-overlay {

    &:after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.7);
    }

    .container,
    .container-sm {

      position: relative;
      z-index: 2;
    }
  }

  &.section-ligh-overlay {

    &:after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.2);
    }
  }

  &.section-lblue {

    background: $lblue;
    color: $coWhite;
  }

  &.section-dblue {

    background: $dblue;
    color: $coWhite;
  }

  &.section-ddblue {

    background: $ddblue;
    color: $coWhite;
  }

  &.section-negative {

    margin-top: -2px;
  }

  &.section-yellow {

    background: $yellow;
    color: $coBlack;
  }

  &.section-dyellow {
    
    background: $dyellow;
    color: $coWhite;
  }

  &.section-blue {

    background: $coBlue;
    color: $white;

  }

  &.section-lbrown {

    background: $lbrown;
  }

  &.section-snippet {

    background: url('../images/home/Group6181.jpg') no-repeat center bottom;
    background-size: cover;
    color: $coWhite;
    padding-bottom: 130px;
  }

  &.section-top-bg {
    
    background: $gray-50 url('../images/squad/Group-6181_01.png') no-repeat top center;
    background-size: 100%;

    h2 {

      color: $white;
    }

  }

  &.flex-grow-1 {

    border-bottom: 1px solid lighten($coBlue, 5%);
  }

  .section-overflow {

    position: relative;
    overflow: auto;
    max-height: 100vh;
  }

  .section-border-top {

    padding: 20px 0;
    border-top: 1px solid $gray-100;
  }

  @include media-breakpoint-up(lg) {

    &.section-min-height {

      min-height: 650px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
