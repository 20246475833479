.gradient {

  .gradient-list {

    display: flex;
    align-items: center;
    
    span {

      position: relative;
      flex-shrink: 0;
      width: 40px;
      text-align: center;

      &:after {

        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        content: '\f077';
        color: #fec340;
        position: absolute;
        bottom: -28px;
        left: calc(50% - 7px);
      }
    }

    &:last-child {

      span {

        &:after {

          display: none;
        }
      }
    }

    .gradient-box {

      flex-grow: 1;
      padding: 15px 20px;
      text-align: center;
    }
  }
}