.modal {

  font-size: rem(16px);

  &.result-modal {

    .btn-close {

      font-size: 16pxpx;
      margin-left: auto;
      padding: 15px;
    }

    .modal-body {

      padding: 30px;

      @include media-breakpoint-down(md) {

        padding: 20px;
      }
    }
  }
}

.modal-content-no-bg {

  background: none;
  border: none;
  color: $coWhite;

  .modal-header {

    border: none;
    margin-bottom: 20px;
  }

  .btn-close {

    font-size: rem(30px);
    color: $coWhite;
    background: none !important;
  }
}