.section-form {

  position: relative;
  background: $coWhite;
  border-radius: 10px;
  padding: 30px;

  .back {

    position: absolute;
    top: 30px;
    left: 30px;

    a {

      color: $gray;
    }

    @include media-breakpoint-down(md) {

      position: relative;
      top: auto;
      left: auto;
      margin-bottom: 30px;
    }
  }

  .section-group {

    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;

    + .section-group {

      padding-top: 30px;
      border-top: 1px solid $gray-100;
    }

    &.section-group-lg {

      max-width: 700px;
      padding-top: 30px;
      padding-bottom: 0;

      + .section-group-lg {

        border-top: 0;
      }
    }
  }

  .section-group {

    .btn {

      border-radius: 10px;

      &.btn-big {

        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  @include media-breakpoint-down(md) {

    padding: 30px 20px;
  }
}

.group-flex {

  display: flex;
  margin-left: -20px;

  .form-flex {

    flex-grow: 1;
    margin-left: 20px;
    // margin-top: 20px;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {

    flex-direction: column;

    .form-flex {

      margin-bottom: 20px;
    }
  }
}

.form-control,
.form-select {

  padding: 10px;
  border-radius: 10px;
}

textarea {

  height: 150px;
}

.form-group {

  + .form-group {

    padding-top: 20px;
  }

  label {

    margin-bottom: 15px;
  }
}

.form-list {

  flex-shrink: 0;
  margin-left: 20px;

  .input-list {

    display: flex;
    align-items: baseline;
    margin-bottom: 5px;

    label {

      margin-left: 10px;
    }
  }
}

.list {

  padding-left: 0;

  &.yellow-list {

    color: $yellow;
  }

  &.orange-list {

    color: $dorange;

  }
}

.accord {

  position: relative;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid $orange;
  margin-top: 30px;

  &:before {
    
    position: absolute;
    top: -9px;
    left: 50px;
    width: 10px;
    height: 9px;
    content: '';
    background: url('../images/page_template/Union-8_02.jpg') no-repeat;
  }

  h3 {

    font-size: rem(20px);

    span {

      font-size: rem(16px);
    }
  }

  &.accord-2 {

    &:before {
    
      left: auto;
      right: 50px;
    }
  }
}

.gray-bg {

  padding: 15px;
  background: $gray-50;
  margin-bottom: 10px;
}

.form-group-flex {

  display: flex;
  margin-left: -20px;

  .flex {

    margin-left: 20px;

    input {

      margin-right: 10px;
    }
  }

  &.flex-3 {

    width: 100%;
    flex-wrap: wrap;    
    
    .flex {

      width: calc(33.333333% - 20px);
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {

      .flex {

        width: calc(50% - 20px);
      }
    }
  }
}

.form-listing {

  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-left: -20px;

  span {

    margin-left: 20px;
    max-width: 50%;

    &:first-child {

      color: $gray;
    }

    &:last-child {

      text-align: right;
    }
  }

  @include media-breakpoint-down(md) {

    flex-direction: column;

    span {

      max-width: 100%;


      &:last-child {

        text-align: left;
      }
    }
  }
}