$whatsappColor : #1ca811;

.sticky {

  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 5;

  a {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(24px);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $coWhite;
    color: $defaultColor !important;
    box-shadow: 0 0 0 0 rgba($coWhite, 1);
    @include transform(scale(1));
    @include animation(pulse 2s infinite);

  }

  @include media-breakpoint-down(md) {

    bottom: 20px;
    right: 20px;

    a {

      font-size: rem(30px);
      width: 55px;
      height: 55px;
    }
  }
  
}

@include keyframes(pulse) {
  
  0% {
    box-shadow: 0 0 0 0 rgba($coWhite, 0.7);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgba($coWhite, 0);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba($coWhite, 0);
  }

}