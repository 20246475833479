.download-form {

  max-width: 900px;
  padding: 30px;
  background: $white;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: -280px;

  .download-inner {

    max-width: 500px;
    margin: 0 auto;
  }
}

.dl-grids {

  display: flex;
  justify-content: center;
  margin-left: -20px;

  .dl-grid {

    max-width: 200px;
    margin-left: 20px;

    span {

      display: block;
    }

    em {

      font-style: normal;
      font-size: rem(40px);
    }


  }

  @include media-breakpoint-down(sm) {

    flex-direction: column;
    align-items: center;

    .dl-grid {

      margin-bottom: 20px;
    }
  }
}

.dl-bottom {

  border-top: 1px solid $gray-100;
  padding-top: 20px;

  .dl-buttons {

    display: flex;
    flex-direction: column;
  }

  .dl-button {

    margin-bottom: 10px;

    .btn {

      width: 135px;
    }
  }
}

.download-list {

  .accordion-list {

    border: 0 !important;
    margin-bottom: 4px;
  }

  .download-grid {

    flex-grow: 1;
    padding: 12px 0;
    background: $yellow;
    cursor: pointer;

    &:hover {

      background: darken($yellow, 10%);
    }

    &.open {

      span {

        &:first-child {

          &:before {

            @include transform(rotate(90deg));
          }
        }
      }
    }

    .container {

      display: flex;

      span {

        flex-grow: 1;

        &:first-child {

          &:before {

            display: inline-block;
            vertical-align: middle;
            font-family: 'Font Awesome 5 Pro'!important;
            font-weight: 700;
            width: 20px;
            content: '\f0da';
            @include transform-origin(left);
          }
        }
      }
    }
  }
}

.download-content {

  &:not(.open) {

    display: none;
  }
}

.download-content-list {

  padding: 20px;
  
  .container {

    display: flex;
  }

  + .download-content-list {

    border-top: 1px solid $gray-100;
  }

  .dl-text {

    flex-grow: 1;
    max-width: 30%;
    margin-right: 20px;

    h3 {

      padding: 0;
      cursor: default;
    }

    span {

      font-size: rem(14px);
      color: $gray;
    }
  }

  .dl-lists {

    flex-grow: 1;
    max-width: 50%;

    .dl-list {

      display: flex;
      padding: 8px 15px;
      background: $white;
      border-radius: 5px;
      align-items: center;
      margin-bottom: 4px;
      height: 51px;

      &.disabled {

        opacity: 0.6;
      }

      .dl-list-btn {

        display: flex;
        align-items: center;
        margin-left: auto;
        flex-shrink: 0;
        padding-left: 10px;

        .dl-btn {

          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          color: $white;
          background: $yellow;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg){

    padding-left: 0;
    padding-right: 0;

    .dl-lists {

      max-width: 100%;
    }
    
  }

  @include media-breakpoint-down(md){

    .container {

      flex-direction: column;
    }
    
    .dl-text {

      max-width: 100%;
      margin-bottom: 20px;
    }
    
  }
}