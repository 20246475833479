.results {

  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid $gray-100;
  border-radius: 10px;
  cursor: pointer;

  i {

    flex-shrink: 0;
    margin-left: auto;
    padding-left: 10px;
  }

  &:hover {

    background: $gray-50;
  }
}