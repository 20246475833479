// include all imported fonts here even google fonts
@charset 'utf-8';
/** font face **/
.font-a { }

@font-face {
  font-family: 'NotoSansTC';
  src: url('font/NotoSansTC-Medium-Alphabetic.woff') format('woff2'),
       url('font/NotoSansTC-Medium-Alphabetic.woff2') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'Magistral';
  src: url('font/MagistralW08Medium.woff2') format('woff2'),
      url('font/MagistralW08Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/** font face **/
