h1 {

  font-size: rem(70px);

  @include media-breakpoint-down(lg) {

    font-size: rem(50px);
  }

  @include media-breakpoint-down(md) {

    font-size: rem(40px);
  }
}

h2 {

  font-size: rem(40px);
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {

    font-size: rem(30px);
  }
}

h3 {
  
  font-size: rem(28px);
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {

    font-size: rem(25px);
  }
}

h4 {

  font-size: rem(20px);
  margin-bottom: 20px;
}

h5 {

  font-size: rem(20px);
  margin-bottom: 20px;
}