.swiper-holder,
.swiper-holder-2,
.swiper-holder-3,
.swiper-top {

  position: relative;

  .swiper-slide {

    height: auto;
  }
}

.swiper-holder-2 {

  padding-bottom: 40%;

  @include media-breakpoint-down(lg) {

    padding-bottom: 66%;
  }

  @include media-breakpoint-down(md) {

    padding-bottom: 100%;
  }

  .swiper-grid-column {
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: calc(100% / 2);
  
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}


.swiper-button-prev,
.swiper-button-next {  

  &:after {

    color: $gray;
    font-size: rem(25px) !important;
  }

  &.circle {

    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($coWhite, 0.4);
    border-radius: 50%;

    &:after {

      display: none;
    }

    i {

      color: $white;
    }

    &.dark-arrow {

      i {

        color: $coBlack;
      }
    }
  }

  &.dark {

    background: rgba($black, 0.5);
  }

  @include media-breakpoint-down(lg) {

    &.circle {

      width: 60px;
      height: 60px;
    }
  }
  
}

@include media-breakpoint-down(md) {

  .swiper-button-prev {  
  
    left: -10px !important;
  }
  
  .swiper-button-next {  
  
    right: -10px !important;
  }
}

.swiper-button-prev {

  left: 0 !important;
  @include transform(translateX(-120%));

  &.inside {
    
    @include transform(translateX(0));
    left: 20px !important;
  }
}

.swiper-button-next {

  right: 0 !important;
  @include transform(translateX(120%));

  &.inside {
    
    @include transform(translateX(0));
    right: 20px !important;
  }
  
}

.swiper-holder-2 {

  .swiper-pagination { 

    bottom: -50px !important;
  }  
}


.swiper-pagination {

  bottom: -40px !important;


  &.swiper-pagination-inside {

    bottom: 30px !important;
  }

  &.hero-pagination {

    bottom: 130px !important;
  }

  .swiper-pagination-bullet {

    position: relative;
    padding: 4px;
    margin: 0 4px !important;
    width: 12px;
    height: 12px;    
    background: rgba($coWhite, 0.8);

    &.swiper-pagination-bullet-active {

      background: $coWhite;
    }
  }

  &.start {

    text-align: left;
  }

  &.dark {

    .swiper-pagination-bullet {

      background: $coBlack;
      opacity: 0.3;

      &.swiper-pagination-bullet-active {

        opacity: 1;
      }
    }
  }
}

.item-bottom {

  position: relative;
  display: flex;
  justify-content: space-between;

  .swiper-pagination {

    position: initial;
    top: auto;
    bottom: auto;
  }
}


.swiper-top,
.swiper-thumb {

  touch-action: pan-y;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;  
}

.swiper-top {  

  .swiper-slide {

    padding-left: 85px;
    padding-right: 85px;
    padding-bottom: 20px;
  }

  .swiper-button-next,
  .swiper-button-prev {

    @include transform(translateX(0));
  }

  .swiper-pagination {

    bottom: 0 !important
  }

  @include media-breakpoint-down(sm) {

    .swiper-button-prev {

      left: 10px !important;
    }

    .swiper-button-next {

      right: 10px !important;
    }
  }

}

.swiper-thumb {

  .swiper-slide {

    height: initial !important;
  }

  // @include media-breakpoint-down(lg) {

  //   .swiper-slide {

  //     height: 100% !important;
  //   } 
  // }
}

.swiper-holder-3 {

  .swiper-slide {

    @include transition(all 0.3s ease);
    @include transform(scale(0.8));

    .text-label {

      opacity: 0;
      @include transition(all 0.3s ease);
    }

    &.swiper-slide-active {

      @include transform(scale(1));

      .text-label {

        opacity: 1;
      }
    }
  }

  
  .swiper-button-prev {

    left: 20vw !important;
    @include transform(translateX(0));

  }

  .swiper-button-next {

    right: 20vw !important;
    @include transform(translateX(0));
    
  }

  @include media-breakpoint-down(md) {

    .swiper-button-prev {

      left: 10px !important;
      width: 30px;
      background: none;
    }
  
    .swiper-button-next {
  
      right: 10px !important;
      width: 30px;
      background: none;
      
    }
  }
}

@include media-breakpoint-down(xxl) {

  .swiper-holder-2 {

    padding-left: 40px;
    padding-right: 40px;
  }

  .swiper-button-prev {

    left: 0;
  }
  
  .swiper-button-next {
  
    right: 0;
    
  }
}
