.accordions {

  width: 100%;

  .accordion-list {
    text-align: left;
    border-top: 1px solid $coWhite;

    .col-blue & {

      color: $white;
    }

    &:last-child {

      border-bottom: 1px solid $coWhite;
    }

    .accordion-content {
      
      padding: 20px;
      background: $dblue;
    }

    h3 {

      display: flex;
      align-items: center;
      font-size: rem(20px);
      position: relative;
      cursor: pointer;
      padding: 20px;
      margin-bottom: 0;
      text-align: left;

      i {

        margin-left: auto;
        font-family: 'Font Awesome 5 Pro' !important;
        padding-left: 10px;

        &:before {

          content: '\f067'
        }
      }

      &.open {

        background: $dblue;

        i {

          &:before {

            content: '\f068'
          }
        }
      }

    }

    @include media-breakpoint-down(lg) {

      h3 {

        font-size: rem(18px);
        padding: 15px;
      }

      .accordion-content {

        padding: 15px;
      }
    }
  }

  .accordion-content {

    padding-top: rem(20px);
    // @include transition(height 0.35s ease-in-out);
    overflow: hidden;

    &:not(.open) {
      display: none;
    }
  }

  &.acc2 {

    .accordion-list {

      border-color: $gray-50;
      
      h3 {

        color: $orange;

        i {
          
          color: $gray;
        }

        &.open {

          background: none;
        }
      }

      .accordion-content {

        color: $gray;

        &.open {
          
          background: none;
        }
      }
    }
  }

}