.tab-navs {
  display: flex;
  align-items: center;
  justify-content: center;

  li {

    margin: 10px;
  }

  a {

    &.active {

      cursor: default;
      pointer-events: none;
    }
  }
}