.grids {

  display: flex;

  .grid-sm {

    position: relative;
    max-width: 350px;
    flex-grow: 1;
    flex-basis: 100%;
    background: $lbrown;
    overflow: auto;
    overflow-x: hidden;
  }

  .grid-lg {

    flex-grow: 1;
    flex-basis: 100%;
  }

  .grid-top {

    ul {

      padding-left: 0;
    }

    li {

      margin-bottom: 10px;
    }
  }

  .grid-nav {

    ul {

      padding-left: 0;
    }

    li {

      a {

        display: block;
        padding: 12px 40px;
        color: $black;
        
        &:hover,
        &.active {

          background: $dorange;
          color: $white;
        }
      }
    }
  }

  .grid-list {

    .g-list {

      margin-bottom: 20px;
      display: flex;
      align-items: center;
      
      .g-img {

        width: 40px;
        margin-right: 20px;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    flex-direction: column;

    .grid-sm {

      max-width: 100%;
    }

    .grid-top {

      ul {

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
      }

      li {

        margin: 5px;
      }

      .btn-ghost {

        border-color: $gray-100;
      }
    }

    .grid-nav {

      margin-bottom: 30px;

      ul {

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        li {

          margin: 5px;
        }

        a {

          display: block;
          padding: 10px 20px;
          border-radius: 30px;
          border: 1px solid $gray-100;
        }
      }
    }
  
  }
}

.gridding {
  border-radius: 10px;

  .grid-img {

    border-bottom: 7px solid $yellow;

    &.grid-img-sm {

      max-width: 300px;
    }

    &.grid-img-bg {

      border-bottom: 0;
      border-radius: inherit;
    }
  }

  .grid-body {

    padding: 20px 15px;
  }

  @include media-breakpoint-down(md) {

    .grid-img {
  
      &.grid-img-sm {
  
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.grid-rec-list {

  margin-bottom: 50px;

  span {

    color: $gray;
  }
  
}

.grid-rectangle {

  position: relative;
  display: flex;
  margin-bottom: 10px;

  &.grid-rec-yellow {

    .grid-rec-img {

      background: rgba($yellow, 0.2);
    }

    .grid-rec-text {

      background-color: $yellow;
    }
  }

  &.grid-rec-blue {

    .grid-rec-img {

      background: rgba($ddblue, 0.2);
    }

    .grid-rec-text {

      background-color: $ddblue;
    }
  }

  &.grid-rec-red {

    .grid-rec-img {

      background: rgba($coRed, 0.2);
    }

    .grid-rec-text {

      background-color: $coRed;
    }
  }

  h3 {

    margin-bottom: 0;
  }

  .grid-rec-inner {

    flex-grow: 1;

    span {

      display: inline-block;
      margin-top: 10px;
    }
  }

  .grid-red-content {
    
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 150px;
  }

  .grid-rec-count {

    font-size: rem(20px);
    width: 30px;
    text-align: center;
    flex-shrink: 0;
    color: $black;

  }

  .grid-rec-img {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 170px;
    flex-grow: 1;
    padding: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

  }

  .grid-rec-text {

    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: $white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .grid-rec-arrow {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    padding: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    @include transform(translateY(-10px));
  }

  @include media-breakpoint-down(md) {


    .grid-red-content {
    
      height: 100px;
    }

    .grid-rec-img {

      max-width: 100px;

      img {

        max-height: 65px;
      }
    }

    .grid-rec-text {

      h3 {

        font-size: rem(20px);
      }
    }

    .grid-rec-arrow {

      width: 60px;
    }
  }

  @include media-breakpoint-down(sm) {

    .grid-rec-arrow {

      display: none;
    }
  }
}