.dropdown {

  &[aria-hidden="true"] {

    display: none !important;
  }

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
  width: 270px;
  background: $dblue;
  z-index: 3;
  padding-left: 0;
  box-shadow: 1px 2px 6px rgba($black, 0.1);

  li {

    width: 100%;
    display: block;
    border: none;
    padding: 0 !important;
    
    + li {

      border-top: 1px solid rgba($coWhite, 0.5);
    }

    a {

      margin: 0 !important;
      display: block;
      padding: 12px 20px !important;
      color: $coWhite !important;
      border: none;

      &:hover {

        background: $orange;
        color: $white !important;
      }
    }
  }

  &.dropdown-lg {

    top: 75px;
    width: 100%;
  }

  &.dropdown-sm {

    width: 100px;
  }

  @include media-breakpoint-down(xl) {

    top: 76px;
  }
}