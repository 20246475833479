// ionclude all your related style here in the footer, you can leave it empty if the page has no footer
footer { 
  z-index: 2; 
  position: relative; 
  background: $coBlue;
  color: $coWhite;

  .footer {

    position: relative;

    a {

      color: $coWhite;

      &:hover {

        opacity: 0.6;
      }
    }

    .footer-top {

      padding-top: 40px;

      ul {

        padding-left: 0;
      }

      li {
        
        margin-bottom: 20px;
      }
    }
    
    .footer-nav {

      padding-left: 20px;
      height: 100%;
      border-left: 1px solid $white;
    }

    @include media-breakpoint-down(md) {

      // .footer-nav {

      //   border-left: 0;
      // }
    }
  }

  .footer-bottom {

    padding: 20px;
    padding-top: 12%;
    background: url('../images/page_template/Mask-Group-49_02.png') no-repeat top center;
    background-size: cover;
  }
}
