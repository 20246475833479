.avatar {

  display: flex;
  
  .avatar-img {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    
    img {

      border-radius: inherit;
    }
  }

  .avatar-content {

    span {

      display: block;

      &.author-name {

        font-size: rem(25px);
        margin-bottom: 10px;
      }
    }
  }
}