.breadcrumbs {

  ol {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    li {

      position: relative;
      list-style: none;
      margin-top: 5px;

      &:after {

        font-family: 'Font Awesome 5 Pro';
        display: inline-block;
        content: '\f054';
        margin: 0 10px;
      }

      &:last-child {

        &:after {

          display: none;
        }
      }

      &.active {

        a {

          opacity: 1;
        }
      }

      a {

        color: $gray;
        opacity: 0.5;
        pointer-events: none;
        cursor: default;


      }
    }
  }
}