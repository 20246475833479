// ionclude all your related style here in the header, you can leave it empty if the page has no header
html, body { height:100%; min-height:100%; }
header {

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: $white;
  @include transition(all 0.3s ease);

  &.scrollDown {

    @include transform(translateY(-101%));
  }

  .header {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 6px 1px rgba($coBlack, 0.1);

    .logo {
      
      margin-left: 30px;
    }

    .header-end {

      display: flex;
      align-items: center;
      margin-top: -15px;
      margin-bottom: -15px;

      .btn {

        border-radius: 0;
        padding: 28px 30px;
        margin-right: -20px;
        margin-left: 20px;
      }

      @include media-breakpoint-down(xl) {

        display: none;
      }
    }

    .menu {

      ul {

        display: flex;
        align-items: center;
        padding-left: 0;
      }      

      li {

        position: relative;
      }

      a {

        display: block;
        padding: 29px 20px;
        color: $coBlue;

        &:hover {

          color: $orange;
        }
      }
    }

    .header-info {
      display: flex;
      flex-shrink: 0;
      // align-items: center;

      span {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 15px;
        color: $coBlue;
        cursor: pointer;
      }

      .lang {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }
  }

  @include media-breakpoint-down(xxl) {

    .header {

      .logo {

        margin-left: 0;
        width: 256px;
      }

      .menu {

        a {

          padding-top: 25px;
          padding-bottom: 25px;
        }
      }

      .header-end {
  
        .btn {
  
          padding: 20px 30px;
        }
      }

    }

  }

  @include media-breakpoint-down(xl) {

    &.scrollDown {

      @include transform(translateY(0));
    }

    .header {

      .menu {

        a {

          padding-left: 7px;
          padding-right: 7px;
        }
      }

      .header-end {

        .btn {

          flex-shrink: 0;
        }
      }

      .header-info {

        span {

          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

*:lang(en) {

  .header {

    .menu {

      a {

        font-size: rem(15px);
        padding: 30px 10px;
      }
    }

    .header-end {

      .btn {

        padding: 27px 30px;
      }
    }

    @include media-breakpoint-down(xxl) {

      .menu {

        a {

          font-size: 14px;
          padding: 30px 8px;
        }
      }

      .header-end {
  
        .btn {
  
          padding: 22px 30px;
        }
      }
    }

    @include media-breakpoint-down(xl) {

      .menu {

        a {

          font-size: 13px;
        }
      }

    }
  }
  
}


