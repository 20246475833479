.search-drawer {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $coWhite;
  z-index: 1065;
  @include transform(translateY(-100%));

  .searh-drawer-container {

    position: relative;
    padding: 40px;
  }

  &.easing {
    @include transition(all 0.3s ease);    
    
  }

  &.open {

    @include transform(translateY(0));
    
  }

  .menu-close {

    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px;
  }

  .search-field,
  .search-field form {

    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
    border-bottom: 1px solid $gray-100;
  }

  .form-control {

    padding: 10px;
    border-radius: 0;
    border: none;
    
  }

  button {

    background: none;
    font-size: 25px;
    border: none;
  }

  @include media-breakpoint-down(md) {

    .searh-drawer-container {

      padding: 50px 20px 20px;
    }
  }
}