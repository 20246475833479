.table-container {

  border-radius: 10px;

  &.table-orange {

    background: $orange;
  }

  &.table-yellow {

    background: $yellow;
  }

  .table {

    color: $white;

    th {

      border-color: rgba($white, 0.4) !important;
    }

    td {

      border: none;
    }

    @include media-breakpoint-down(md) {

      min-width: 600px;
    }
  }
}