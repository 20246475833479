.burger-menu {

  font-size: rem(25px);
  cursor: pointer;

  &.hide {

    display: none;
  }

  @include media-breakpoint-up(xl) {

    display: none;
  }
}

.mobile-menu {

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 65px;
  right: 0;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background: $dblue;
  color: $coWhite;
  z-index: 1060;
  @include transform(translateX(100%));

  &.easing {
    @include transition(all 0.3s ease);    
    
  }

  &.open {

    @include transform(translateX(0));
    
  }

  .sns {

    padding: 10px 20px;
    border-top: 1px solid $gray-200;
  }

  .menu {

    li {

      display: block;
      border-top: 1px solid $gray-200;

      i {

        margin-left: auto;
        padding: 10px;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: -10px;
      }

    }
  }

  .dropdown {

    position: relative;
    top: 0;
    width: 100%;
    background: $dblue;

    a {

      padding: rem(15px 20px) !important;
    }
  }

  @include media-breakpoint-up(xl) {

    display: none;

  }

  @include media-breakpoint-down(xl) {

    .menu {

      li {

        a {

          padding: 13px 20px;
        }
      }
    }
  }
  
}

.menu-container {

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  height: 100%;
  border-top: 1px solid $gray-200;

}

.menu-holder {
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
  padding-bottom: 65px;

  ul {

    padding-left: 0;
  }

  li {

    a,
    span {

      display: flex;
      align-items: center;
      font-size: rem(16px);
      padding: rem(15px 20px);
      border-bottom: 1px solid rgba($coWhite, 0.2);
      color: $coWhite;

      i {

        font-size: rem(22px);
        padding: 10px;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: -10px;

        &:last-child {

          margin-left: auto;
        }
      }
    }

  }

  &.submenu-holder {

    li {

      a,
      span {
  
        i {
  
  
          margin-left: 0;
          padding: 0;
          margin-top: -6px;
          margin-right: 10px;
        }
      }
  
    }
  }

}

.menu-closes {

  font-size: rem(25px);
  border: none;
  background: none;

  &:not(.show) {

    display: none;
  }
}

.mobile-menu-footer {

  margin-top: 20px;
  padding: 15px;

  .search-btn-mobile {

    border: none;
    color: $coWhite;
    outline: none;
    background: none;
  }

  .mobile-lang {

    font-size: rem(16px);

    a {

      color: $coWhite;
      margin-left: 20px;
    }
  }

  .btn {
    padding: 20px;
    border-radius: 0;
  }
}


.search {

  padding: 15px 0;
  
  input[type="text"] {
    padding: 8px;
    padding-right: 35px;
    border-radius: 0;
  }

  .search-inner {

    position: relative;
  }

  .search-btn {

    font-size: rem(18px);
    padding: 7px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
  }
}
