.card-colored {

  padding: 20px;
  display: flex;
  border-radius: 8px;
  min-height: 375px;
  height: 100%;

  span {

    display: block;
    flex: 0 0 40px;
    width: 40px;
    font-size: rem(20px);
    font-weight: 700;
    margin-right: 20px;
  }

  h4 {

    font-weight: 700;
  }

  .card-colored-body {

    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .card-colored-img {

    margin-bottom: 30px;
    display: flex;
    height: 80px;
    align-items: center;
  }

}